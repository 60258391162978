import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { EnumRoutePaths } from 'src/configs/routes';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import MainLayout from 'src/Layouts/MainLayout';
import WithSliderLayout from 'src/Layouts/WithSliderLayout';
import { setLocation } from 'src/store/app/actions';
import { resetCategories } from 'src/store/categories/actions';
import { setOrder } from 'src/store/order/actions';
import { IRootState } from 'src/types/store-types';
import { Loader } from '../atoms/Loader/Loader';
import SocialIcons from '../common/SocialIcons';
import { CityPopup } from '../popups/CityPopup';

const Tours = lazy(() => import('../pages/Tours'));
const TourBookingPage = lazy(() => import('../pages/TourBookingPage'));
const ToursPage = lazy(() => import('../pages/ToursPage'));
const Privacy = lazy(() => import('../pages/Privacy'));
const About = lazy(() => import('../pages/About'));
const CategoriesPage = lazy(() => import('../pages/CategoriesPage'));
const CartPage = lazy(() => import('../pages/CartPage'));
const _404_Page = lazy(() => import('../pages/404'));

const AnonimusRoutes: FC = () => {
  const [visible, setVisible] = useState(false);
  const { /* location, lng, */ cities } = useSelector((state: IRootState) => state.app, shallowEqual);

  const setLocationAction = useConnectedAction(setLocation);
  const resetCategoriesAction = useConnectedAction(resetCategories);
  const setOrderDataAction = useConnectedAction(setOrder);

  const handleLoad = (): void => {
    setVisible(true);
  };

  const handleWindowOnLoad = (): void => {
    if (window.location.pathname !== '/') {
      const city = window.location.pathname.split('/')[1];
      if (city == 'hurghada' || city == 'Hurghada') {
        sessionStorage.setItem('city', JSON.stringify('Hurghada'));
      } else if (city == 'Aswan' || city == 'aswan') {
        sessionStorage.setItem('city', JSON.stringify('Aswan'));
      } else {
        sessionStorage.setItem('city', JSON.stringify('Sharm El Sheikh'));
      }
    }
    if (sessionStorage.getItem('city')) {
      setLocationAction(JSON.parse(sessionStorage.getItem('city') as string));
    } else {
      handleLoad();
    }
  };
  useEffect(() => {
    handleWindowOnLoad();
  }, []);

  return (
    <MainLayout>
      <section className="app-content-container">
        <SocialIcons color="black" className="static" />
        <Routes>
          <Route
            path={EnumRoutePaths.PRIVACY}
            element={
              <Suspense fallback={<Loader />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.TAXI}
            element={
              <Suspense fallback={<Loader />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.ABOUT}
            element={
              <Suspense fallback={<Loader />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.CAREGORY_TOUR}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <Tours />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.CAREGORIES}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <CategoriesPage />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.VIP_TOURS}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="vipTours" fromToursToday={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />

          <Route
            path={EnumRoutePaths.TOUR_BOOKING}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <TourBookingPage />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.TOURS_TODAY}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="toursToday" fromToursToday={true} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.BASE_1}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="mostPopular" fromToursToday={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path={EnumRoutePaths.CART}
            element={
              <Suspense fallback={<Loader />}>
                <CartPage />
              </Suspense>
            }
          />
          <Route
            path={EnumRoutePaths.BASE}
            element={
              <WithSliderLayout>
                <Suspense fallback={<Loader />}>
                  <ToursPage headerName="mostPopular" fromToursToday={false} />
                </Suspense>
              </WithSliderLayout>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <_404_Page headerName="Incorect Route" />
              </Suspense>
            }
          />
        </Routes>
      </section>
      <CityPopup
        visible={visible}
        setVisible={setVisible}
        cities={cities}
        setCity={setLocation}
        resetCategories={resetCategoriesAction}
        setOrderData={setOrderDataAction}
      />
    </MainLayout>
  );
};

export default AnonimusRoutes;
