export type orderState = {
  orderState: {
    name: string;
    email: string;
    confirm: string;
    contact: string;
    phoneNumber: string;
    hotel: string;
    room: string;
    city: string;
    firstDate: string;
    lastDate: string;
    paymentMethod: string;
    peopleCount: number;
    childCount: number;
    cardholderCard: string;
    cardNumber: string;
    cvv2: string;
    expireDate: string;
  };
  isLoading: boolean;
};

export const SET_ORDER_DATA = 'order/SET_ORDER_DATA';
export const GET_ORDER_DATA = 'order/GET_ORDER_DATA';

export const RESET_ORDER_DATA = 'order/RESET_ORDER_DATA';

export const ORDER_CHECKOUT = 'order/ORDER_CHECKOUT';
