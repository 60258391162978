/**
 * Set images config for images in public/images folder
 */
export const AVATAR_DEFAULT_IMAGE = 'https://f0.pngfuel.com/png/980/886/male-portrait-avatar-png-clip-art.png';

export const images = {
  logo: '/images/logo.svg',
  en: '/images/flags/en.png',
  pt: '/images/flags/pt.png',
  es: '/images/flags/es.png',
};
export const sliderImages = [
  '/images/slider/1.webp',
  '/images/slider/2.webp',
  '/images/slider/3.webp',
  '/images/slider/4.webp',
  '/images/slider/5.webp',
];

export const paymentImages = {
  googlePay: '/images/paymentMethods/google-pay-logo.webp',
  applePay: '/images/paymentMethods/apple-pay-logo.webp',
  mastercard: '/images/paymentMethods/master-card-logo.webp',
  visa: '/images/paymentMethods/visa-card-logo.webp',
  mir: '/images/paymentMethods/mir-card-logo.webp',
  cash: '/images/paymentMethods/cash-payment-logo.webp',
};
