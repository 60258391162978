import { FC } from 'react';
import Footer from 'src/components/common/Footer';
import Header from 'src/components/common/Header';

const MainLayout: FC<any> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
