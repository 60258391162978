/* eslint-disable max-len */
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/types/store-types';

const SliderContentDesc: FC = () => {
  const { t } = useTranslation();
  const { lng } = useSelector((state: IRootState) => state.app);
  const text: string = useMemo(() => {
    if (lng === 'Eng') {
      return "Discover Egypt like never before with our specialized tour platform, designed exclusively for those who seek authentic and unforgettable experiences. Whether you're exploring the ancient wonders of Cairo, diving into the vibrant underwater world of the Red Sea, or immersing yourself in the rich culture of Luxor, our platform offers handpicked tours that cater to your every need. With a focus on the best that Egypt has to offer, we provide personalized itineraries, expert guides, and seamless booking—all to ensure your journey is as unique as the land itself.";
    } else if (lng === 'Rus') {
      return 'Откройте для себя Египет, как никогда раньше, с нашей специализированной туристической платформой, созданной для тех, кто ищет подлинные и незабываемые впечатления. Будь то исследование древних чудес Каира, погружение в яркий подводный мир Красного моря или погружение в богатую культуру Луксора, наша платформа предлагает тщательно отобранные туры, которые удовлетворят любые ваши потребности. Мы сосредоточены на лучшем, что может предложить Египет, предоставляя персонализированные маршруты, опытных гидов и простое бронирование — все это для того, чтобы ваше путешествие было таким же уникальным, как и сама эта страна.';
    } else {
      return '';
    }
  }, [lng]);
  return (
    <div className="content-container">
      <div className="sliderText-container">
        <div className="header-container">{t('about')}</div>
        <div className="text-container">{text}</div>
      </div>
    </div>
  );
};

export default SliderContentDesc;
