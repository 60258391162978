import { Modal } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { makePath } from 'src/utils/helpers';

import { useConnectedAction } from 'src/hooks/use-connected-action';
import { resetImagesArr } from 'src/store/app/actions';
import './cityModal.scss';

type Props = {
  visible: boolean;
  setVisible: (v: boolean) => void;
  cities: string[];
  setCity: (city: string) => void;
  resetCategories: any;
  setOrderData: any;
};

export const CityPopup: FC<Props> = ({ visible, setVisible, cities, setCity, resetCategories, setOrderData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const resetImagesArrAction = useConnectedAction(resetImagesArr);

  const handleSelectCity = (city: string): void => {
    const location = makePath(city);
    sessionStorage.setItem('city', JSON.stringify(location));
    setCity(location);
    setVisible(!visible);
    resetCategories();
    setOrderData('city', city);
    resetImagesArrAction();
    navigate(`/${location}`);
  };
  return (
    <div className="modal-container">
      <Modal
        open={visible}
        footer={null}
        closable={false}
        onCancel={() => setVisible(false)}
        centered={true}
        className={'city-modal'}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
        maskClosable={false}
      >
        <div className="modal-header-container">
          {/* <p className="modal-header">
                                {t("WelcomeTO")}
                            </p> */}
          <img src="/images/icons/location.svg" />
          <p className="modal-header-text">{t('chooseLocation')}</p>
        </div>
        <div className="modal-btn-container">
          {cities.map((city) => (
            <div key={city} className="modal-button link" onClick={() => handleSelectCity(city)}>
              {t(city)}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
