import { fork } from 'redux-saga/effects';
import { watchCategories } from './categories/saga';
import { watchOrders } from './order/saga';
import { watchTours } from './tours/saga';

export default function* rootSaga(): any {
  yield fork(watchCategories);
  yield fork(watchTours);
  yield fork(watchOrders);
}
