import { createAction } from 'deox';
import {
  ADD_TO_CARD,
  APP_SET_CART_COUNT,
  GET_CATEGORY_TOURS,
  GET_ORDER_DATA,
  GET_SINGLE_TOUR,
  GET_TOURS,
  GET_VIP_TOURS,
  MUTATE_CARD,
  REMOVE_FROM_CARD,
  RESET_SINGLE_TOUR,
  RESET_TOURS,
  SET_ORDER_DATA,
  SET_SINGLE_TOUR,
  SET_TOURS,
} from './types';

export const setOrder = createAction(SET_ORDER_DATA, (res) => (params) => res(params));
export const getOrder = createAction(GET_ORDER_DATA, (res) => (params) => res(params));

export const getTourById = createAction(GET_SINGLE_TOUR, (res) => (params) => res(params));
export const setTourById = createAction(SET_SINGLE_TOUR, (res) => (params) => res(params));
export const resetSingleTour = createAction(RESET_SINGLE_TOUR);

export const getTours = createAction(GET_TOURS, (res) => (params) => res(params));
export const setTours = createAction(SET_TOURS, (res) => (params) => res(params));

export const getVipTours = createAction(GET_VIP_TOURS, (res) => (params) => res(params));

export const resetTours = createAction(RESET_TOURS);

export const getCategoryTours = createAction(GET_CATEGORY_TOURS, (res) => (params) => res(params));

export const addToCard = createAction(ADD_TO_CARD, (res) => (params) => res(params));

export const setCartCount = createAction(APP_SET_CART_COUNT, (res) => (params) => res(params));

export const mutateCart = createAction(MUTATE_CARD, (res) => (params) => res(params));
export const removeFromCart = createAction(REMOVE_FROM_CARD, (res) => (params) => res(params));
