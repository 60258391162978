import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import { IRootState } from 'src/types/store-types';
import { makePath } from 'src/utils/helpers';
import ResponsiveTabs from './ResponsiveTabs';
import './styles.scss';

export const mostPopular = 'mostPopular';

const Tabs: FC = () => {
  const { t } = useTranslation();
  const { location } = useSelector((state: IRootState) => state.app);
  const splitedKeysOfPathName = window.location.pathname.split('/');
  const pathName = splitedKeysOfPathName[3];

  let page: any = '';
  switch (pathName) {
    case 'tours-today':
      page = 'toursToday';
      break;
    case 'categories':
      page = 'category';
      break;
    case 'vip-tours':
      page = 'vipTours';
      break;
    default:
      page = 'mostPopulars';
  }

  const [activeTab, setActive] = useState<string>(page);
  const [_activeIcon, setActiveIcon] = useState<string>('star');
  const [isVisibleDisplay, toggleVisibilityDisplay] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (page) {
      setActive(page);
    }
  }, [page]);

  const handleTabClick = (cb: () => void, tab: string, icon: string): void => {
    cb();
    setActive(tab);
    setActiveIcon(icon);
    toggleVisibilityDisplay(!isVisibleDisplay);
  };

  return (
    <div className="tabs">
      <div className="tabs-container">
        <Link to="/" className={`${activeTab === 'mostPopulars' ? 'active' : ''}`}>
          <div className="tab" onClick={() => handleTabClick(() => setActive('mostPopulars'), 'mostPopulars', 'star')}>
            <div className="tab-icon" style={{ width: 25, height: 25, color: '#0072fd' }}>
              <SvgIcon src="/images/icons/star.svg" />
            </div>
            <span className="tabs-name">{t('mostPopular')}</span>
          </div>
        </Link>

        <div className="tab disabled-tab">
          <div className="tab-icon" style={{ width: 25, height: 25, color: '#0072fd' }}>
            <SvgIcon src="/images/icons/timetable.svg" />
          </div>
          <span className="tabs-name">{t('todayTours')}</span>
        </div>

        <Link to={`/${makePath(location)}/categories`} className={`${activeTab === 'category' ? 'active' : ''}`}>
          <div className="tab" onClick={() => handleTabClick(() => setActive('category'), 'category', 'list')}>
            <div className="tab-icon" style={{ width: 25, height: 25, color: '#0072fd' }}>
              <SvgIcon src="/images/icons/list.svg" />
            </div>
            <span className="tabs-name">{t('toursByCategory')}</span>
          </div>
        </Link>

        <Link to={`/${makePath(location)}/vip-tours`} className={`${activeTab === 'vipTours' ? 'active' : ''}`}>
          <div className="tab vip-tours" onClick={() => handleTabClick(() => setActive('vipTours'), 'vipTours', 'vip')}>
            <span className="tabs-name">{t('vipTours')}</span>
          </div>
        </Link>
      </div>
      {width <= 800 && <ResponsiveTabs location={makePath(location)} />}
    </div>
  );
};

export default Tabs;
