export type tourState = {
  tours: any;
  singleTour: any;
  cartToursArray: any[];
  cartCount: number;
};

export const SET_TOUR_DATA = 'tours/SET_TOUR_DATA';
export const MUTATE_TO_CART = 'tours/MUTATE_TO_CART';

export const GET_ORDER_DATA = 'tours/GET_ORDER_DATA';
export const SET_ORDER_DATA = 'tours/SET_ORDER_DATA';

export const GET_TOURS = 'tours/GET_TOURS';
export const SET_TOURS = 'tours/SET_TOURS';
export const RESET_TOURS = 'tours/RESET_TOURS';

export const GET_SINGLE_TOUR = 'tours/GET_SINGLE_TOUR';
export const SET_SINGLE_TOUR = 'tours/SET_SINGLE_TOUR';
export const RESET_SINGLE_TOUR = 'tours/RESET_SINGLE_TOUR';

export const GET_VIP_TOURS = 'tours/GET_VIP_TOURS';

export const GET_CATEGORY_TOURS = 'tours/GET_CATEGORY_TOURS';

export const ADD_TO_CARD = 'tours/ADD_TO_CARD';
export const MUTATE_CARD = 'tours/MUTATE_CARD';
export const REMOVE_FROM_CARD = 'tours/REMOVE_FROM_CARD';

export const APP_SET_CART_COUNT = 'app/APP_SET_CART_COUNT';
