import { IMGIX_PATHS } from './constants';

const convertToImgix = (imgPath: string, width?: string, height?: string): string => {
  if (imgPath.includes('.gif')) {
    return imgPath;
  } else if (imgPath.includes(IMGIX_PATHS.sourcePath)) {
    let newPath = `${imgPath.replace(IMGIX_PATHS.sourcePath, IMGIX_PATHS.imgixPath)}?auto=compress&fm=avif`;
    if (width) {
      newPath += `&w=${width.replace('px', '')}`;
    }
    if (height) {
      newPath += `&h=${height.replace('px', '')}`;
    }
    return newPath;
  }
  return imgPath;
};

export { convertToImgix };
