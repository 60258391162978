import { call, takeLatest } from 'redux-saga/effects';
import { checkoutReq } from 'src/services/orderService';
import { ORDER_CHECKOUT } from './types';

function* checkoutSaga({ payload }: any): any {
  try {
    const res: any = yield call(checkoutReq, payload);

    console.log(res);
  } catch {
    console.log('error');
  }
}

export function* watchOrders(): any {
  yield takeLatest(ORDER_CHECKOUT, checkoutSaga);
}
