import { createReducer } from 'deox';
import produce from 'immer';
import { getOrder, resetOrderData, setOrder } from './actions';
import { orderState } from './types';

const DEFAULT_ORDER_DATA = {
  name: '',
  email: '',
  confirm: '',
  contact: 'telegram',
  phoneNumber: '',
  hotel: '',
  room: '',
  city: sessionStorage.city ? JSON.parse(sessionStorage.city) : '',
  firstDate: '',
  lastDate: '',
  paymentMethod: 'cash',
  peopleCount: 1,
  childCount: 1,
  cardholderCard: '',
  cardNumber: '',
  cvv2: '',
  expireDate: '',
};

export const orderInitialState: orderState = {
  orderState: DEFAULT_ORDER_DATA,
  isLoading: false,
};

export const orderReducer = createReducer(orderInitialState, (handle) => [
  handle(setOrder, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.orderState = {
        ...state.orderState,
        [payload.name]: payload.value,
      };
    })
  ),
  handle(getOrder, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.isLoading = payload.isLoading;
    })
  ),

  handle(resetOrderData, (state) =>
    produce(state, (draft) => {
      draft.orderState = DEFAULT_ORDER_DATA;
    })
  ),
]);
