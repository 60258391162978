import axios from 'axios';
import { SUCCESS_STATUSES } from 'src/utils/constants';

const headers = {
  'content-type': 'application/json',
};

const onFulfilled = (response: any): any => response.data;

const filterValue = <T>(value: T): boolean => value !== undefined && value !== null;

// function getToken(): string {
//   return JSON.parse(localStorage.getItem('user') as string)?.token || '';
// }

// const setHeaders = (axiosApi: AxiosInstance, tokenFree?: boolean): void => {
// const token = getToken();
// if (token && !tokenFree) {
//   axiosApi.defaults.headers['x-access-token'] = getToken();
// }
// };

class HttpClient {
  public async get<T = any>(
    url: { path: string; baseURL: string } | string,
    params?: Record<string, any>,
    config?: any,
    serializeParams?: boolean
  ): Promise<any> {
    let path = '';
    let axiosApi = null;

    if (typeof url === 'string') {
      path = url;
      axiosApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    } else {
      path = url.path;
      axiosApi = axios.create({
        baseURL: url.baseURL,
      });
    }

    path = params && !serializeParams ? path + '?' + this.getQueryString(params, config?.joinQueryArrayValues) : path;
    // setHeaders(axiosApi);

    try {
      const response = await axiosApi.get<T>(
        path,
        serializeParams ? { ...config, ...headers, params } : { ...config, ...headers }
      );

      return onFulfilled(response);
    } catch (error: any) {
      return error;
      // return onRejected(error);
    }
  }

  public post<T = any, P = any>(
    url: { path: string; baseURL: string } | string,
    data?: P,
    params?: Record<string, any>,
    config?: any
  ): Promise<any> {
    let path = '';
    let axiosApi = null;

    if (typeof url === 'string') {
      path = url;
      axiosApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    } else {
      path = url.path;
      axiosApi = axios.create({
        baseURL: url.baseURL,
      });
    }

    path = params ? path + '?' + this.getQueryString(params, config?.joinQueryArrayValues) : path;
    // setHeaders(axiosApi, tokenFree);

    return axiosApi
      .post<T>(path, data, { ...config, ...headers })
      .then(onFulfilled)
      .catch((error) => {
        return error;
      });
  }

  public patch<T = any, P = any>(
    url: { path: string; baseURL: string } | string,
    data?: P,
    params?: Record<string, any>,
    config?: any
  ): Promise<any> {
    let path = '';
    let axiosApi = null;

    if (typeof url === 'string') {
      path = url;
      axiosApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    } else {
      path = url.path;
      axiosApi = axios.create({
        baseURL: url.baseURL,
      });
    }

    path = params ? path + '?' + this.getQueryString(params, config?.joinQueryArrayValues) : path;

    return axiosApi
      .patch<T>(path, data, { ...config, ...headers })
      .then(onFulfilled)
      .catch((error) => {
        return error;
      });
    // .catch(onRejected);
  }

  public put<T = any, P = any>(
    url: { path: string; baseURL: string } | string,
    data?: P,
    params?: Record<string, any>,
    config?: any
  ): Promise<any> {
    let path = '';
    let axiosApi = null;

    if (typeof url === 'string') {
      path = url;
      axiosApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    } else {
      path = url.path;
      axiosApi = axios.create({
        baseURL: url.baseURL,
      });
    }

    path = params ? path + '?' + this.getQueryString(params, config?.joinQueryArrayValues) : path;
    return axiosApi
      .put<T>(path, data, { ...config, ...headers })
      .then(onFulfilled)
      .catch((error) => {
        return error;
      });
    // .catch(onRejected);
  }

  public delete<T = any>(
    url: { path: string; baseURL: string } | string,
    params?: Record<string, any>,
    config?: any
  ): Promise<any> {
    let path = '';
    let axiosApi = null;

    if (typeof url === 'string') {
      path = url;
      axiosApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
    } else {
      path = url.path;
      axiosApi = axios.create({
        baseURL: url.baseURL,
      });
    }

    path = params ? path + '?' + this.getQueryString(params, config?.joinQueryArrayValues) : path;

    return axiosApi
      .delete<T>(path, { ...config, ...headers })
      .then(onFulfilled)
      .catch((error) => {
        return error;
      });
    // .catch(onRejected);
  }

  public getQueryString(params: Record<string, any>, joinArrayValues = false): string {
    return Object.entries(params)
      .filter(([_, value]) => filterValue(value))
      .map(([key, value]) => {
        if (!Array.isArray(value)) {
          return key + '=' + encodeURIComponent(value);
        } else if (joinArrayValues) {
          return (
            key +
            '=' +
            value
              .filter(filterValue)
              .map((item) => encodeURIComponent(item))
              .join(',')
          );
        } else {
          return value
            .filter(filterValue)
            .map((item) => `${key}[]` + '=' + encodeURIComponent(item))
            .join('&');
        }
      })
      .filter(Boolean)
      .join('&');
  }

  public isOkStatus(status: number): boolean {
    return SUCCESS_STATUSES.includes(status);
  }
}

export default new HttpClient();
