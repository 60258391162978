import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRootState } from 'src/types/store-types';
import { makePath } from 'src/utils/helpers';
import ContactUs from '../ContactUs';
import SocialIcons from '../SocialIcons';
import './styles.scss';

type Props = {
  newClass?: string;
  handleClick?: () => void;
};

const Footer: FC<Props> = ({ newClass, handleClick }) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const { location } = useSelector((state: IRootState) => state.app);
  const [visible, setVisible] = useState(false);
  const close = (): void => {
    setVisible(false);
  };
  const toogleModal = (): void => {
    setVisible(true);
    if (handleClick) {
      handleClick();
    }
  };
  return (
    <footer className={`footer-container  ${newClass}`}>
      <SocialIcons color="#fff" className="footer-icon" />
      <p className="footer-text">
        {t('copyright')} &copy; {year} TouRest.online{' '}
      </p>
      <p className="footer-text">{t('copyright')}.</p>
      <div className="footer-navigation">
        <Link to={`/${makePath(location)}/about`} className="footer-nav" onClick={handleClick}>
          {t('about')}
        </Link>
        <Link to={`/${makePath(location)}/privacy`} className="footer-nav" onClick={handleClick}>
          {t('privacyPolicy')}
        </Link>
        <div className="footer-nav" onClick={() => toogleModal()}>
          {t('contact')}
        </div>
      </div>
      <ContactUs visible={visible} close={close} />
    </footer>
  );
};

export default Footer;
