import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line
// @ts-ignore
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem('selectedLanguage') || 'Eng',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: false,
    detection: {},
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
