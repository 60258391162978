import { message, Modal } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isValidObject } from 'src/utils/helpers';
// import { sendEmail } from '../../store/global/global.actions';
import './styles.scss';

type Props = {
  visible: boolean;
  close: () => void;
};

const ContactUs: FC<Props> = ({ visible, close }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: '',
    surname: '',
    email: '',
    message: '',
  });
  const handleChange = (name: string, value: string): void => {
    setData({ ...data, [name]: value });
  };
  const handleSubmit = (): any => {
    if (isValidObject(data)) {
      if (isValidEmail(data.email as string)) {
        console.log(data);
      } else {
        return message.error(t('invalidEmail'));
      }
    } else {
      return message.error(t('requiredFiledsMessage'));
    }
    close();
  };
  return (
    <div>
      <Modal open={visible} onCancel={close} centered={true} className="contact-us" footer={null}>
        <div className="contact-us-body">
          <div className="form-input-container">
            <div className="contact-header">{t('contactUs')}</div>
            <form className="form" autoComplete="off">
              <div className="contact-input">
                <label className="label">{t('name')}*</label>
                <input
                  name="name"
                  value={data.name}
                  type="text"
                  className="form-input"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="contact-input">
                <label className="label">{t('surname')}*</label>
                <input
                  name="surname"
                  value={data.surname}
                  type="text"
                  className="form-input"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="contact-input">
                <label className="label">{t('email')}*</label>
                <input
                  name="email"
                  type="email"
                  value={data.email}
                  className="form-input"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="contact-input">
                <label className="label">{t('message')}*</label>
                <input
                  name="message"
                  type="type"
                  value={data.message}
                  className="form-input"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </form>
            <div className="form-footer" onClick={() => handleSubmit()}>
              <div className="send-buuton">{t('send')}</div>
            </div>
          </div>
          <div className="contact-footer">
            <address>
              <a href="mailto:info@tourest.online">info@tourest.online</a>
            </address>
            <address>
              {' '}
              <span>{t('address')}</span>
            </address>
            <address>
              {' '}
              <a href="tel: +374 93 245235">+374 93 245235</a>
            </address>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUs;
