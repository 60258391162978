import { FC } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from './store';

import Routes from 'src/components/Routes';
import ErrorBoundaryComponent from 'src/hoc/ErrorBoundary';

import './styles/index.scss';

const App: FC = () => {
  return (
    <Provider store={store}>
      <ErrorBoundaryComponent>
        <div className="wrapper">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes />
        </div>
      </ErrorBoundaryComponent>
    </Provider>
  );
};

export default App;
