import { createReducer } from 'deox';
import produce from 'immer';
import { getCategories, resetCategories } from './actions';
import { categoriesState } from './types';

export const categoriesInitialState: categoriesState = {
  imagesArr: [],
  categories: [],
};

export const categoryReducer = createReducer(categoriesInitialState, (handle) => [
  handle(resetCategories, (state) =>
    produce(state, (draft) => {
      draft.categories = [];
    })
  ),
  handle(getCategories.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.categories = payload;
    })
  ),
]);
