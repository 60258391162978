import { Menu } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import { mostPopular } from './Tabs';

type Props = {
  location: string;
};

const ResponsiveTabs: FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const [activeHeader, setActiveHeader] = useState(mostPopular);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { SubMenu } = Menu;

  const handleTabClick = (key: string): void => {
    setActiveHeader(key);
    setOpenKeys([]); // Collapse the menu
  };

  const handleOpenChange = (keys: string[]): void => {
    setOpenKeys(keys); // Control open state of the menu
  };

  return (
    <div className="responsive-tab">
      <Menu
        mode="inline"
        expandIcon={<img src={'/images/icons/menu.png'} alt="menu" />}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
      >
        <SubMenu title={activeHeader === mostPopular ? t('mostPopular') : t(activeHeader)} key="submenu">
          <Menu.Item onClick={() => handleTabClick('mostPopular')} key="mostPopular">
            <Link to={'/'}>
              <div className="tab">
                <div
                  className="tab-icon"
                  style={{
                    width: 25,
                    height: 25,
                    color: '#0072fd',
                  }}
                >
                  <SvgIcon src="/images/icons/star.svg" />
                </div>
                <span className="tabs-name"> {t('mostPopular')}</span>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="toursToday" disabled>
            <div className="tab disabled-tab">
              <div
                className="tab-icon"
                style={{
                  width: 25,
                  height: 25,
                  color: '#0072fd',
                }}
              >
                <SvgIcon src="/images/icons/timetable.svg" />
              </div>
              <span className="tabs-name">{t('toursToday')}</span>
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => handleTabClick('categories')} key="categories">
            <Link to={`/${location}/categories`}>
              <div className="tab">
                <div
                  className="tab-icon"
                  style={{
                    width: 25,
                    height: 25,
                    color: '#0072fd',
                  }}
                >
                  <SvgIcon src="/images/icons/list.svg" />
                </div>
                <span className="tabs-name"> {t('toursByCategory')}</span>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => handleTabClick('vipTours')} key="vipTours">
            <Link to={`/${location}/vip-tours`}>
              <div className="tab">
                <span className="tabs-name"> {t('vipTours')}</span>
              </div>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

export default ResponsiveTabs;
