import { Modal } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { makePath } from 'src/utils/helpers';
import Footer from '.././common/Footer';
import './headerModal.scss';

type Props = {
  visibleModal: boolean;
  setVisibleModal: (visible: boolean) => void;
  cities: string[];
  setCity: (city: string) => void;
  resetCategories: any;
  lng: string;
  setOrderData: any;
  setLng: any;
};

const HeaderModal: FC<Props> = ({
  visibleModal,
  setVisibleModal,
  cities,
  setCity,
  resetCategories,
  lng,
  setLng,
  setOrderData,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [activetab, setActive] = useState(lng);

  const handleSelectLng = (lng: string): void => {
    setLng(lng);
    i18n.changeLanguage(lng);
    setActive(lng);
    setVisibleModal(false);
  };

  const handleSelectCity = (city: string): void => {
    const location = makePath(city);
    sessionStorage.setItem('city', JSON.stringify(location));
    setCity(location);
    setVisibleModal(!visibleModal);
    resetCategories();
    setOrderData('city', city);
    navigate(`/${location}`);
  };

  return (
    <div>
      <Modal
        open={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
        className={'modal-conatiner'}
        style={{ width: window.innerWidth }}
      >
        <div className="modal-city-container">
          {cities.map((city) => (
            <div key={city} className="city link" onClick={() => handleSelectCity(city)}>
              {t(city)}
            </div>
          ))}
        </div>
        <div className="header-content language-container link">
          <div className={`lng-container`}>
            <div className="header-content-tab link">
              <p onClick={() => handleSelectLng('Eng')} className={activetab === 'Eng' ? 'active' : ''}>
                Eng
              </p>
            </div>
            <div className="header-content-tab link">
              <p onClick={() => handleSelectLng('Rus')} className={activetab === 'Rus' ? 'active' : ''}>
                Рус
              </p>
            </div>
          </div>
        </div>
        <Footer newClass="header-modal-footer" handleClick={() => setVisibleModal(false)} />
      </Modal>
    </div>
  );
};

export default HeaderModal;
